import React from "react";
import SiteHeader from "../../components/SiteHeader";
import Footer from "../SiteFooter";
import Block from "../Block";
import Title from "../Title";
import { TYPE_GOLD } from "../../constants";
import PriceHistory from "../../components/PriceHistory";

const BlogPageLayout = ({ pageNumber, location, children }) => (
    <div className="container">
        <SiteHeader location={location} />
        {!pageNumber ? (
            <div>
                <Block leftIndent>
                    <Title type={"h1"} color={"gold"} mb={50} mt={30}>
                        Zajímavé informace
                        <br />o investování do zlata
                    </Title>
                </Block>
            </div>
        ) : null}
        <Block radialGradient>
            <Title color={"gold"} type={"h2"} mb={20} pt={40}>
                Ze světa cenných kovů
            </Title>
            <div>{children}</div>
        </Block>
        <PriceHistory type={TYPE_GOLD} variant="blog" />
        <Footer />
    </div>
);

export default BlogPageLayout;
