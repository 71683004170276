import React, { Component } from "react";
import { graphql, navigate, Link } from "gatsby";

import PostEntry from "../components/PostEntry";
import BlogPageLayout from "../components/BlogPageLayout";
import Seo from "../components/Seo";

import styles from "../components/PostEntry/post-entry.module.sass";
import Breadcrumb from "../components/BreadCrumb";

class IndexPage extends Component {
    renderPreviousLink = () => {
        const {
            pageContext: { pageNumber },
        } = this.props;

        let previousLink = null;

        if (!pageNumber) {
            return null;
        } else if (1 === pageNumber) {
            previousLink = "zajimavosti-o-investicnich-kovech/";
        } else if (1 < pageNumber) {
            previousLink = `/zajimavosti-o-investicnich-kovech/${pageNumber -
                1}`;
        }

        return (
            <Link
                to={previousLink}
                type="primary"
                onClick={() => navigate(previousLink)}
                className={styles.prevPageLink}
            >
                <span>Novější články</span>
                <img src={require("../images/icons_new/more.svg")} alt="" />
            </Link>
        );
    };

    renderNextLink = () => {
        const {
            pageContext: { hasNextPage, pageNumber },
        } = this.props;

        if (hasNextPage) {
            return (
                <Link
                    to={`/zajimavosti-o-investicnich-kovech/${pageNumber + 1}`}
                    type="primary"
                    onClick={() =>
                        navigate(
                            `/zajimavosti-o-investicnich-kovech/${pageNumber +
                                1}`
                        )
                    }
                    className={styles.nextPageLink}
                >
                    <span>Starší články</span>
                    <img src={require("../images/icons_new/more.svg")} alt="" />
                </Link>
            );
        } else {
            return null;
        }
    };

    render() {
        const {
            data,
            location,
            pageContext: { pageNumber },
            path,
        } = this.props;

        const blogPageNumber = pageNumber ? ` Stránka ${pageNumber}` : "";
        return (
            <>
                <BlogPageLayout pageNumber={pageNumber} location={location}>
                    <Seo
                        title={`Zajímavosti o investičním zlatě${blogPageNumber}`}
                        description={
                            "Užitečné články a tipy, které si přečtěte, když se zabýváte investováním do zlata. Překvapivé informace i nové poznatky."
                        }
                        url={path}
                    />
                    <Breadcrumb
                        crumbs={[
                            {
                                label: "Články",
                                link: "zajimavosti-o-investicnich-kovech",
                            },
                        ]}
                        leftIndent={false}
                    />
                    <div>
                        <div>
                            <ul className={styles.wrapper}>
                                {data &&
                                    data.wpgraphql &&
                                    data.wpgraphql.posts.nodes.map(post => (
                                        <PostEntry post={post} key={post.id} />
                                    ))}
                            </ul>
                        </div>
                        <div className={styles.pagination}>
                            {this.renderPreviousLink()}
                            {this.renderNextLink()}
                        </div>
                    </div>
                </BlogPageLayout>
            </>
        );
    }
}

export default IndexPage;

export const query = graphql`
    query GET_POSTS($ids: [ID]) {
        wpgraphql {
            posts(where: { in: $ids }, first: 999) {
                nodes {
                    ...PostEntryFragment
                }
            }
        }
    }
`;
