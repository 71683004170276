import React from "react";
import moment from "moment";
import { Link, graphql } from "gatsby";
import styles from "./post-entry.module.sass";

const PostEntry = ({ post }) => (
    <li key={post.id}>
        <Link to={`/zajimavosti-o-investicnich-kovech/${post.uri}`}>
            {post.featuredImage ? (
                <div
                    className={styles.featureImage}
                    style={{
                        backgroundImage: `url(${post.featuredImage.sourceUrl})`,
                    }}
                />
            ) : (
                <div className={styles.featureImageEmpty} />
            )}
            <div className={styles.content}>
                <h3>{post.title}</h3>
                <p className={styles.date}>
                    {moment(post.date).format("D. M. YYYY")}
                </p>
                <span className={styles.fakeLink}>
                    <span>Celý článek</span>
                    <img
                        src={require("../../images/icons_new/more.svg")}
                        alt=""
                    />
                </span>
            </div>
        </Link>
    </li>
);

export default PostEntry;

export const query = graphql`
    fragment PostEntryFragment on WPGraphQL_Post {
        id
        title
        uri
        slug
        date
        content: excerpt
        featuredImage {
            sourceUrl(size: MEDIUM)
        }
        author {
            name
            slug
            avatar(size: 100) {
                url
            }
        }
    }
`;
